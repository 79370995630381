// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eng-js": () => import("./../../../src/pages/eng.js" /* webpackChunkName: "component---src-pages-eng-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-sales-helicopters-for-sale-js": () => import("./../../../src/pages/sales/helicopters/for_sale.js" /* webpackChunkName: "component---src-pages-sales-helicopters-for-sale-js" */),
  "component---src-pages-sales-helicopters-other-other-js": () => import("./../../../src/pages/sales/helicopters/other/other.js" /* webpackChunkName: "component---src-pages-sales-helicopters-other-other-js" */),
  "component---src-pages-sales-index-js": () => import("./../../../src/pages/sales/index.js" /* webpackChunkName: "component---src-pages-sales-index-js" */),
  "component---src-pages-sales-learn-js": () => import("./../../../src/pages/sales/learn.js" /* webpackChunkName: "component---src-pages-sales-learn-js" */),
  "component---src-templates-helicopters-by-model-js": () => import("./../../../src/templates/HelicoptersByModel.js" /* webpackChunkName: "component---src-templates-helicopters-by-model-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/NewsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */)
}

